import React, {useState, useEffect, useRef} from 'react'
import ProductRow from './ProductRow';
import '../../styles/search.css';
import searchicon from "../../assets/img/search-icon.png";
import noimage from "../../assets/img/no-image.jpg";
import {Spinner} from '@chakra-ui/react'
import ReactHtmlParser from "react-html-parser";
import {graphql, useStaticQuery} from "gatsby";

const prods = []

const OpenSearch = (props) => {

    const [search, setSearch] = useState((props.searchField ? props.searchField : ""));
    const [query, setQuery] = useState((props.searchField ? props.searchField : ""));
    const [prods, setProds] = useState([])
    const [products_infos, setProductsInfos] = useState([]);
    const [products_infos_final, setProductsInfosFinal] = useState([]);
    const [spinner, setSpinner] = useState(true)
    const [numberOfIncorrectProduct, setIncorrectProd] = useState(0)
    const [loading, setLoading] = useState(false)

    const searchInput = useRef(null)


    const productsdata = useStaticQuery(graphql`
        query {
          allProductS3 {
            edges {
              node {
                hit {
                  name
                  type
                  brand
                  children {
                    name
                    type
                  }
                }
              }
            }
          }
        }
      `)


    /*useEffect(() => {
        getResults();
    }, [query]);
    useEffect(() => {
        getFilters();
    }, [query]);*/
    useEffect(() => {
        console.log("called")
        getProducts();
    }, [query]);
    /*useEffect(() => {
        getProductInformations();
    });*/


    /*if (props.searchField) {
        console.log(props.searchField)
        //setQuery(props.searchField)
        //setQueryFunction(props.searchField)
    } else {
        console.log("DATA NOT SENT")
    }*/


    function setQueryFunction(field) {
        setSearch(field)
    }

    const isCorrectProduct = (product) => {

        let isTrue = false;

        productsdata.allProductS3.edges.map((obj, idx) => {
            if (obj.node.hit.name === product.infos.reference) {
                console.log(product.infos.reference)
                isTrue = true;
                return isTrue;
            }
        })

        return isTrue;
    }

    const correctListOfProducts = (products) => {
        let incorrectProd = 0;
        let tab = [];

        //Eliminer les produits non consernés
        products.map(res => {
            if (isCorrectProduct(res)) {
                tab.push(res)
            }
        })

        console.log(tab)

        //Récuperer un tableau avec les bons images
        tab.map((res, index) => {
            let images_temp = []
            console.log(res, getProperImagesFromS3(getS3Product(res.infos.reference)))
            if (res.images)
                res.images.map(img => {
                    if (getProperImagesFromS3(getS3Product(res.infos.reference))
                        .includes(getImageNameFromURL(img.url))) {
                        images_temp.push(img)
                    }
                })
            tab[index].images = images_temp
            console.log(tab)
        })

        console.log(tab)


        //Ordonner l'ordre des images
        tab.map((res, index) => {
            tab[index].images = changeImagesOrder(res.images)
        })

        //console.log(tab)


        return tab
    }

    function changeImagesOrder(images) {
        let tab = []

        //Les premiers positions pour les _ph.
        images.map((image, index) => {
            if (getImageNameFromURL(image.url).includes("_ph")) {
                tab.push(image)

                images.splice(index, 1)
            }
        })

        //La deuxieme priorités pour les -1 jusqu'à 10
        for (let i = 1; i <= 10; i++) {
            images.map((image, index) => {
                if (getImageNameFromURL(image.url).includes("-" + i)) {
                    tab.push(image)
                    images.splice(index, 1)
                }
            })
        }

        //Ajouter les autres images qui restent au tableau
        /* images.map((image, index) => {
                 tab.push(image)
                 images.splice(index, 1)
             })*/

        return tab.concat(images)
    }

    function getS3Product(ref) {
        let prod = {}
        productsdata.allProductS3.edges.map((obj, idx) => {
            if (obj.node.hit.name === ref) {
                prod = obj;
                return prod
            }
        })
        return prod
    }

    function getImageNameFromURL(url) {
        return url.split("/").pop()
    }

    function getProperImagesFromS3(product) {
        let images = []
        product.node.hit.children.map((files) => {
            if (
                files.type === null &&
                !files.name.includes(".txt") &&
                !files.name.includes("-thumb.")
            ) {
                images.push(files.name)
            }
        })

        return images
    }


    const getProductInformations = async (ref) => {
        //console.log("getProductInformations function")
        const response = await fetch(
            `https://5306h0oq6b.execute-api.eu-central-1.amazonaws.com/opensearch-api-test?q=${ref}`
        );
        const data = await response.json();

        let item = {}
        item.images = [];


        data.hits.hits.map((hit, index) => {
            if (hit._source.reference === ref) {
                if ("usine" in hit._source) {
                    item.infos = hit._source
                } else {
                    let referentiels = []
                    hit._source.results.map((res, indx) => {
                        if (!referentiels.includes(res)) {
                            referentiels.push(res);
                        }
                    })

                    item.images.push(
                        {
                            "url": hit._source.image_url,
                            "results": referentiels
                        }
                    )
                }
            }
        })


        //setProdInfos(item)
         //console.log(item)
        return item
    }


    const getProducts = async () => {

        if (onlySpaces(query)) return
        setSpinner(false)
        //console.log("getProducts function")
        const response = await fetch(
            `https://5306h0oq6b.execute-api.eu-central-1.amazonaws.com/opensearch-api-test?q=${query}`
        );

        const data = await response.json();
        let references = [];

        data.hits.hits.map((hit, index) => {
            if (references.indexOf(hit._source.reference) === -1) {
                references.push(hit._source.reference)
            }
        })

        let test = []
        references.map(async ref => {
            let temp = await getProductInformations(ref)
            //console.log(temp)
            if ("infos" in temp)
                test.push(temp)
            //prods.push(temp)
            //setProductsInfos([...products_infos, products_infos.push(temp)])
        })


        setTimeout(function () {
            setProductsInfos(test)
            setSpinner(true)
        }, 1000);

        console.log(products_infos)
        setSearch('');
    }

    /*const getResults = async () => {
        const response = await fetch(
            `https://5306h0oq6b.execute-api.eu-central-1.amazonaws.com/opensearch-api-test?q=${query}`
        );
        const data = await response.json();
        // setProducts(data.hits.hits);
        // console.log(data.hits.hits);

        const dataSets = data.hits.hits;
        // const refs = [...new Set(dataSets.map((item) => item._source.reference))];

        const uniqueResult = [];
        const unique = dataSets.filter(element => {
            const isDuplicate = uniqueResult.includes(element._source.reference);
            if (!isDuplicate) {
                uniqueResult.push(element._source.reference);
                return true;
            }
        });
         console.log(unique);

        setProducts(unique);

    }

    const getFilters = async () => {
        const response = await fetch(
            `https://5306h0oq6b.execute-api.eu-central-1.amazonaws.com/opensearch-api-test?q=${query}`
        );
        const data = await response.json();
        const dataSets = data.hits.hits;
        const groups = dataSets.reduce((groups, item) => {
            const group = (groups[item._source.reference] || []);
            group.push(`${item._source.image_url}`);
            groups[item._source.reference] = group;
            return groups;
        }, []);
        const groupItems = Object.values(groups);
        console.log(groupItems);

        setLists(groupItems);
    }*/

    const updateSearch = e => {
        //console.log(e)
        setSearch(e.target.value);

        // console.log(search);
    }


    const getSearch = e => {


        e.preventDefault();

        console.log(searchInput.current.value)
        setSearch(searchInput.current.value)
        console.log(search)


        //setSearch('');

    }


    useEffect(()=> {
        console.log("search => "+search)
        if (search != query && search !== '') {
            console.log(search)
            setSpinner(false)
            setQuery(search);
            setProductsInfos([])
            setSpinner(true)
        }
    },[search])

    const renderVarious = () => {
        if (products_infos.length > 1) {
            return 's';
        } else {
            return '';
        }
    }


    function onlySpaces(str) {
        return str.trim().length === 0;
    }


    return (
        <div className="App">
            <div className="search-page">
                <div>
                    <form onSubmit={getSearch} className='search-form'>
                        <input className='search-bar' id="searchInput" type="text" ref={searchInput}/>
                        <button className='search-button' type="submit"><img src={searchicon} alt=""/></button>
                    </form>
                </div>
                {
                    (!onlySpaces(query))
                        ? ReactHtmlParser("<h1>Résultat de votre recherche</h1>\n")
                        : ""
                }
                {
                    (!onlySpaces(query) && spinner)
                        ? ReactHtmlParser("<p class=\"numbers\">" + correctListOfProducts(products_infos).length + " résultat" + renderVarious() + "</p>")
                        : ""
                }


                {/*{lists.map((res) => (
                    <Listmodal
                        images={res}
                    />
                ))}*/}

                {correctListOfProducts(products_infos).sort(function (a, b) {
                    if (a.infos.reference < b.infos.reference) {
                        return -1;
                    }
                    if (a.infos.reference > b.infos.reference) {
                        return 1;
                    }
                    return 0;
                }).map((result, index) => {
                    console.log(result)
                    //changeImagesOrder(result)


                    return (
                        <ProductRow product={result}/>
                    )

                })}
            </div>
            <center>
                <Spinner size='xl' hidden={spinner}/>
            </center>

        </div>
    );

}

export default OpenSearch;
