import React from 'react'
import {Footer} from "../components/footer/Footer.js"
import {Header} from "../components/header/Header.js"
import {Container} from "@chakra-ui/react"
import Opensearch from "../components/search-items/opensearch"
import {useParams} from "react-router-dom";

const isBrowser = typeof window !== "undefined";
console.log(isBrowser)
function searchpage(props) {
    //console.log(props.location.state.searchField)

    let urlParams;
    if (isBrowser){
        urlParams = new URLSearchParams(window.location.search);
    }

    //console.log(urlParams.get('value'))
    return (
        <>
            <Header/>
            <Container maxW='container.xl'>
                <Opensearch
                    searchField={(props.location.state) ? props.location.state.searchField : (isBrowser) ? urlParams.get('value') : null}/>
            </Container>
            <Footer/>
        </>
    )
}

export default searchpage