import React, {useState} from 'react'
import playIcon from "../../assets/img/Play.png"
import {Link} from 'gatsby'
import Modal from 'react-modal'
import Modalresults from "../../pages/modal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Modal.setAppElement('#main')


const ProductRow = (props) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [numero, setNumero] = React.useState(0);


    function openModal() {
        setIsOpen(true);
        setNumero(0)
    }

    function closeModal() {
        setIsOpen(false);
    }

    function nextImage() {
        if (numero >= props.product.images.length - 1) {
            setNumero(0)
            return
        }
        if (numero < props.product.images.length) {
            setNumero(numero + 1)
        }
    }

    function previousImage() {
        if (numero > 0) {
            setNumero(numero - 1)
        } else {

            setNumero(props.product.images.length - 1)
        }
    }


    //console.log(props.product)
    //console.log(numero)


    return (
        <>
            <div id="main">
                <div className="item">
                    <div className="image">
                        <img width="250"
                             src={(props.product.images.length > 0) ? props.product.images[0].url : "/no_photo.jpeg"}
                             alt=""/>
                        <Link to="#" onClick={openModal}>
                            Voir les photos de l'article
                        </Link>
                    </div>
                    <div className="content">
                        <div className="reference">
                            <h3>{(props.product) ? props.product.infos.reference + " - " + props.product.infos.usine + " - " + props.product.infos.code : ""}</h3>
                        </div>
                        <div className="ref-usine">
                            <h3><span className="pre-title">CODE SETEM :</span>
                                <strong>{(props.product) ? props.product.infos.reference : ""}</strong></h3>
                        </div>
                        <div className="br-list">
                            <div className="ref-setem">
                                <h3><span className='pre-title'>DESIGNATION USINE :</span>
                                    <strong>{(props.product) ? props.product.infos.usine : ""}</strong></h3>
                            </div>
                            <div className="code">
                                {(props.product) && props.product.infos.code.replaceAll(" ", "")?
                                    <h3><span className="pre-title">CODE USINE :</span>
                                        <strong>{(props.product) ? props.product.infos.code : ""}</strong></h3>
                                    :""}
                            </div>
                            <div className="brand">
                                <h3><span className="pre-title">MARQUE :</span>
                                    <strong>{(props.product) ? props.product.infos.brand.replaceAll("_"," ") : ""}</strong></h3>
                            </div>
                        </div>
                        <div className="link-more">
                            <a href={`/produit/${(props.product) ? props.product.infos.reference : ""}`}><span><img
                                src={playIcon} alt=""/></span> En savoir plus</a>
                        </div>
                    </div>

                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <div className="modal-container">
                            <div className="modal-top">
                                <div className="content-top">
                                    <div className="modal-results">
                                        <center>
                                            <label>{(props.product ? props.product.images.length : 0)} résultats
                                                - {numero + 1}/{(props.product ? props.product.images.length : 0)}</label>
                                        </center>
                                    </div>
                                    <div className="modal-title">
                                        <label>{(props.product) ? props.product.infos.reference + " - " + props.product.infos.usine + " - " + props.product.infos.code : ""}</label>
                                    </div>
                                </div>
                                <div className="container-description">
                                    <div className="">
                                        <img src="/fleche-bleu-gauche.png" onClick={previousImage}
                                             className="previousBtn"/>
                                    </div>

                                    <div className="content">
                                        <div className="ref-usine">
                                            <h3><span
                                                className='pre-title'>DESIGNATION USINE :</span>{(props.product) ? props.product.infos.usine : ""}
                                            </h3>
                                        </div>
                                        <div className="br-list">
                                            <div className="ref-setem">
                                                <h3><span className="pre-title">CODE SETEM :</span>
                                                    <strong>{(props.product) ? props.product.infos.reference : ""}</strong>
                                                </h3>
                                            </div>
                                            <div className="code">
                                                {(props.product) && props.product.infos.code.replaceAll(" ", "")?
                                                <h3><span className="pre-title">CODE USINE :</span>
                                                    <strong>{(props.product) ? props.product.infos.code : ""}</strong>
                                                </h3>
                                                    :""}
                                            </div>
                                            <div className="brand">
                                                <h3><span className="pre-title">MARQUE :</span>
                                                    <strong>{(props.product) ? props.product.infos.brand.replaceAll("_"," ") : ""}</strong>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <img src="/fleche-bleu-droite.png" onClick={nextImage} className="nextBtn"/>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-core">
                                <div className="core-content">
                                    <div className="image">
                                        <img
                                            src={(props.product.images.length > 0) ? props.product.images[numero].url : ""}
                                            alt=""/>
                                    </div>
                                    <div className="details">
                                        {(props.product.images.length > 0) ? props.product.images[numero].results.map((res, idx) => {
                                            return (
                                                <label>{res}, </label>
                                            )
                                        }) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Modal>

                </div>
            </div>
        </>
    )
}

export default ProductRow